.viewTicketButton{
color: DimGray;
border: 1px solid DimGray;
border-radius: 15px;
width: 30%;
margin-bottom: 2%;
margin-top: 4%;
margin-left: 3%;
background-color: #eeeded;
}

.viewTicketButton:hover{
    color: black;
    border: 1px solid black;
    background-color: #dbd8d8;
    }

    .viewTicket{
        color: #404040;
        border: 1px solid #303030;
        border-radius: 15px;
        width: 30%;
        background-color: #eeeded;
        margin-top: 2%;
        padding: 4px;
        }

        @media(min-width: 1000px){
        .viewTicket{
            color: #404040;
            border: 1px solid #303030;
            border-radius: 15px;
            width: 20%;
            background-color: #eeeded;
            margin-top: 2%;
            padding: 5px;
            }
        }

        @media(min-width: 1000px){
            .viewTicket:hover{
                background-color: #dbd8d8;
                width: 20%;
                }
            }
        
        .viewTicket:hover{
            background-color: #dbd8d8;
            }


        .editmodaltitle{
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-weight: bold;
            color: #404040;
        }
        .editmodaltext{
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            color: #404040;
        }

        .form-group-custom {
            margin-bottom: 6px;
        }

        .editPicture:hover {
            font-size: 20px !important;
        }

        .deletePicture:hover {
            font-size: 25px !important;
        }