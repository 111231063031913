@import url('https://fonts.googleapis.com/css?family=Josefin+Sans+Futura+Raleway+Lato+Montserrat+Roboto+Alegreya');
.landingheadings {
  font-family: 'Lato';
  font-weight: bold;
}

/* .landingheadings {
  font-family: 'Lato';
} */

.landingheadings2 {
  font-family: 'Futura';
  font-weight: bolder;
}

.jumbotron{
  position: relative;
  z-index: 10;
  margin-top: 4em;
  border: 1px solid black;
  opacity: 0.9;
}


#test123{
  background-image: url(../Assets/blackback3.jpeg);
  /* background-image: url(../Assets/confetti.gif); */
  /* z-index: 20; */
}

#test1234{
  background-image: url(../Assets/rain.gif);
  /* z-index: 20; */
}

#tryfree_heading {
  font-size: 45px
}

@media (max-width: 1000px){
#tryfree_heading {
  font-size: 40px
}
}

@media (max-width: 750px){
  #tryfree_heading {
    font-size: 35px
  }
  }

  .partygraphic{
    width: 674px;
      height: 450px;
    }

    /* @media (max-width: 1200px) {
    .partygraphic{
      width: 809px;
      height: 540px;
      }
    } */
  
    @media (max-width: 1000px) {
    .partygraphic{
      width: 674px;
      height: 450px !important;
      }
    }  

    @media (max-width: 1000px) {
      .partygraphic{
        width: 561px;
        height: 375px !important;
        }
      }  
    
    @media (max-width: 500px) {
      .partygraphic{
        width: 467px;
        height: 312px !important;
        }
      }  
    
        @media (max-width: 400px) {
          .partygraphic{
            width: 325px;
            height: 350px !important;
            }
          }  

          @media (max-width: 500px) {
            .activityvector{
              width: 464px !important;
              height: 310px !important;
              }
            } 

            @media (max-width: 500px) {
              .identity_verification{
                width: 320px !important;
                height: 320px !important;
                }
              } 

            @media (max-width: 500px) {
              .customrelationships{
                width: 358px;
                height: 262px !important;
                }
              } 

          .excelintegration{
            width:52%;
            height:32%;
          }
          @media (max-width: 1000px) {
          .excelintegration{
            width:63%;
            height:39%;
          }
        }
          @media (max-width: 800px) {
          .excelintegration{
            width:69%;
            height:43%;
          }
        }
          @media (max-width: 500px) {
          .excelintegration{
            width:100%;
            height:100%;
          }
        }
        @media (max-width: 500px) {
          .qrlanding{
            width:310px;
            height:310px;
          }
        }

        

        @media (max-width: 400px) {
          .apicloud{
            width: 325px;
            height: 300px !important;
            }
          }  
   




.securityline{
width: 700px;
height: 330px;
}

@media (max-width: 600px) {
.securityline{
  width: 505px;
  height: 250px;
  }
}  

@media (max-width: 500px) {
  .securityline{
    width: 454.5px;
    height: 225px;
    }
  }  

  @media (max-width: 500px) {
    .securityline{
      width: 454.5px;
      height: 225px;
      }
    }  

    @media (max-width: 450px) {
      .securityline{
        width: 431.775px;
        height: 213.75px;
        }
      }  

  @media (max-width: 390px) {
    .securityline{
      width: 404px;
      height: 200px;
      }
    }  

    .phoneapp {
      width: 17%;
      height:27%;
    }

    @media (max-width: 1000px) {
    .phoneapp {
      width: 27%;
      height:13%;
    }
  }

  @media (max-width: 600px) {
    .phoneapp {
      width: 30%;
      height:14%;
    }
  }

    @media (max-width: 390px) {
    .phoneapp {
      width:50%;
      height:80%;
    }
  }
.getStartedButton {
  background: linear-gradient(to top left, #fe8d99 , #CC0000);
 
  text-shadow: #fe8d99 1px 1px 1px;
  border: none;
  color: white;
  padding: 15px;
  font-size: 17px;
  width: 160px;
  font-family: Verdana;
}

.getStartedButton:hover {
  background: linear-gradient(to bottom right, #f5bbc1 , #CC0000);

}

.lead{
  margin-top: 1em
}

.leadBtn{
  margin-top: 2em
}
.leadBtn a:nth-child(1){
  width: 6.5em;
  margin-left: 2em
}
.leadBtn a:nth-child(2) {
  width: 6.5em;
  margin-right: 2em
}

.VideostyleBanner {
  z-index: -1;
  position:absolute;
  width:100.1%;
  left:50%;
  top:20%;
  height:670px;
  object-fit: cover;
  transform:translate(-50%, -50%);
}



.signupbutton:hover {
  color: white;
  font-size: 26px;
  float: right;
  margin: 90;
  background-color: #3457D5;
}

.signupbutton {
  margin: "20px";
  font-size: 25px;
  float: right;
  color: white;
  margin: 90;
  margin-left: 200;
  background-color: #1E90FF;
  width: 200px;
}

.loginbutton:hover {
  color: white;
  font-size: 26px;
  float: right;
  margin: 90;
  background-color: #118a7e;
}

.loginbutton {
  margin: "20px";
  font-size: 25px;
  float: right;
  color: white;
  margin: 90;
  background-color: #3baea0;
  width: 200px;
}



@media (max-width: 985px) {
  .landingbuttons {
    padding-left: 20px;
padding-right: 20px;
  }
  
}

@media (max-width: 430px) {
  .landingbuttons {
    padding-left: 20px;
padding-right: 20px;
  }
}


@media (max-width: 985px) {
  .signupbutton {
    margin: "16px";
    font-size: 25px;
    float: right;
    color: white;
    margin: 90;
    width: 150px;
  }
  
}

@media (max-width: 985px) {
  .loginbutton {
    margin: "16px";
    font-size: 25px;
    float: right;
    color: white;
    margin: 90;
    width: 150px;
  }
}

@media (max-width: 985px) {
  .signupbutton:hover {
    /* padding: 2px 120px 10px 30px;
    box-shadow: 10px 5px 5px black;
    background-color: #3457D5; */
    margin: "16px";
    font-size: 25px;
    float: right;
    color: white;
    margin: 90;
    width: 150px;
  }
  
}

@media (max-width: 985px) {
  .loginbutton:hover {
    margin: "16px";
    font-size: 25px;
    float: right;
    color: white;
    margin: 90;
    width: 150px;
  }
}


@media (max-width: 710px) {
  .longlinelanding {
    height: 300px;
    width: 450px;
  }
}

@media (max-width: 450px) {
  .longlinelanding {
    height: 200px;
    width: 300px;
  }
}

@media (max-width: 600px) {
  .signupbutton {
    margin: "16px";
    font-size: 22px;
    float: right;
    color: white;
    margin: 90;
    width: 120px;
  }
}

@media (max-width: 600px) {
  .loginbutton {
    margin: "16px";
    font-size: 22px;
    float: right;
    color: white;
    margin: 90;
    width: 120px;
  }
}

@media (max-width: 600px) {
  .signupbutton:hover {
    margin: "16px";
    font-size: 22px;
    float: right;
    color: white;
    margin: 90;
    width: 120px;
  }
}

@media (max-width: 600px) {
  .loginbutton:hover {
    margin: "16px";
    font-size: 22px;
    float: right;
    color: white;
    margin: 90;
    width: 120px;
  }
}

@media (max-width: 500px) {
  .signupbutton {
    margin: "16px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}

@media (max-width: 500px) {
  .loginbutton {
    margin: "16px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}

@media (max-width: 500px) {
  .signupbutton:hover {
    margin: "16px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}

@media (max-width: 500px) {
  .loginbutton:hover {
    margin: "16px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}



@media (max-width: 420px) {
  .signupbutton {
    margin: "14px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
    padding-right: 0px;
    padding-left: 10px;
  }
}

@media (max-width: 420px) {
  .loginbutton {
    margin: "14px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}

@media (max-width: 420px) {
  .signupbutton:hover {
    margin: "14px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 420px) {
  .loginbutton:hover {
    margin: "14px";
    font-size: 20px;
    float: right;
    color: white;
    margin: 90;
    width: 100px;
  }
}


.landingbuttons{
padding-left: 20%;
padding-right: 20%;
}

@media (min-width: 800px) {
  .landingbuttons {
  padding-left: 25%;
padding-right: 25%;
}
}

  @media (max-width: 500px) {
    .landingbuttons {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
